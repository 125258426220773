import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import Link from "gatsby-link";
import { Section, BreadCrumb } from "../components/Section";
import { ArrowRight, HomeIcon, ArrowLineIcon } from "../components/Icons";
import Image from "../components/image";
import SecondaryButton from "../components/Button/SecondaryButton";
import BreakpointDown from "../components/Media/BreakpointDown";

const ContentWrtapper = styled.div`
  display: flex;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &:before {
    border: 25px solid rgba(203, 209, 226, 0.27);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 560px;
    height: 560px;
    border-radius: 100%;
    ${BreakpointDown.xl`
    width:460px;
    height:460px;
 `}
    ${BreakpointDown.md`
    width:260px;
    height:260px;
 `}
  }
  &:after {
    content: "";
    width: 380px;
    height: 380px;
    border-radius: 100%;
    display: block;
    background: rgba(203, 209, 226, 0.27);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    ${BreakpointDown.xl`
    width:260px;
    height:260px;
 `}
    ${BreakpointDown.xl`
    width:160px;
    height:160px;
 `}
  }
`;
const InnerContent = styled.div`
  position: relative;
  text-align: center;
  margin-top: 40px;
  z-index: 2;
  h1 {
    font-size: 84px;
    text-align: right;
    margin-bottom: 25px;
    ${BreakpointDown.xl`
        font-size:50px;
      `}
  }
  a {
    color: #121316;
    font-weight: 600;
  }
  p {
    margin-top: 20px;
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 36px;
    max-width: 570px;
    ${BreakpointDown.md`
     line-height:30px;
   `}
  }
`;
const ImgWrapper = styled.div`
  max-width: 100%;
  width: 460px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  ${BreakpointDown.xl`
     width:360px;
  `}
  ${BreakpointDown.sm`
     width:160px;
  `}
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section bgColor="transparent" pt="180px" xpt="60px" pb="180px">
      <BreadCrumb top="75" xtop="15px">
        <div className="container">
          <Link to="/" className="line-after-heading">
            <HomeIcon /> <ArrowRight />
            404 Error
          </Link>
        </div>
      </BreadCrumb>
      <div className="container">
        <ContentWrtapper>
          <InnerContent>
            <h1>Oops!</h1>
            <ImgWrapper>
              <Image name="404-error.png" alt="404" />
            </ImgWrapper>
            <p>
              Sorry, we can't find the page you are looking for click here to go
              back to the <Link to="/">Home Page</Link>.
            </p>
            <Link to="/">
              <SecondaryButton text="go to homepage" icon={<ArrowLineIcon />} />
            </Link>
          </InnerContent>
        </ContentWrtapper>
      </div>
    </Section>
  </Layout>
);

export default NotFoundPage;
